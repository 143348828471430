import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import AppInput from "../Shared/AppInput";
import {useFormik} from "formik";
import * as yup from "yup";
import http from './../../services/http.service';
import * as jwtService from './../../services/jwt.service';
import AppAlert from "../Shared/AppAlert";
import useAlert from "../../hooks/useAlert";
import MyOpinionLogo from '../../assets/images/MyOpinion_Logo_Head.png';
import {loggedIn, loggedOut} from "../../actions/authAction";
import {connect} from "react-redux";
import {extractResponseValidationErrors} from "../../utils/index";

const Login = ({history, loggedIn, loggedOut, ...props}) => {

	const [submitting, setSubmitting] = useState(false);
	const [alert, setAlert, onClose] = useAlert();

	const initialValues = {
		email: '',
		password: '',
	};

	useEffect(() => {
		jwtService.remove(loggedOut);
	}, []);


	const onSubmit = async (values) => {
		setSubmitting(true);
		setAlert(null);
		try {
			const {data} = await http.post('/auth/login', values);
			jwtService.set(data, loggedIn);

			setAlert({
				type: 'success',
				message: 'Authentification reussie !'
			});

			setTimeout(() => {
				window.location.replace('/');
			}, 1500);

		} catch ({response}) {
			const {data, status} = response;

			if(response && status == 401) {
				setAlert({
					type: 'danger',
					message: data
				})
			}
			if(response && status == 422) {
				setAlert({
					type: 'danger',
					message: extractResponseValidationErrors(data)[0]
				})
			}
			else if(response && status == 500) {
				setAlert({
					type: 'danger',
					message: 'Erreur de traitement, veuillez contacter les administrateurs'
				})
			}
		} finally {
			setSubmitting(false);
		}
	};

	const formik = useFormik({
		initialValues,
		validationSchema: yup.object().shape({
			email: yup.string().ensure().email().required('Champ obligatoire'),
			password: yup.string().ensure().required('Champ obligatoire').min(6, 'Le mot de passe doit etre au minimun 6 caracteres')
		}),
		onSubmit
	})

	return (
		<>
			<div className="pattern">
				<span className="red"/>
				<span className="indigo"/>
				<span className="blue"/>
				<span className="green"/>
				<span className="orange"/>
			</div>
			<div className="auth-main particles_js">
				<div className="auth_div vivify popIn">
					<div className="auth_brand">
						<Link className="navbar-brand" to="/">
							<img src={MyOpinionLogo} width="120" height="120" className="d-inline-block align-top mr-2" alt="Oculux logo" />
                        </Link>
					</div>
					<div className="card">
						<div className="body">
							<p className="lead">Espace moderateur</p>
							<form className="form-auth-small m-t-20" onSubmit={formik.handleSubmit}>
								{alert && <AppAlert onClose={onClose} type={alert.type}>{alert.message}</AppAlert>}
								<AppInput
									name="email"
									label="Adresse email"
									value={formik.values.email}
									error={formik.errors.email}
									touched={formik.touched.email}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								<AppInput
									name="password"
									label="Mot de passe"
									type="password"
									value={formik.values.password}
									error={formik.errors.password}
									touched={formik.touched.password}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								<button type="submit" disabled={submitting} className="btn btn-primary btn-round btn-block font-weight-bold">
									{submitting ? 'Connexion en cour...' : 'Me connecter'}
								</button>
								<div className="bottom">
										<span className="helper-text m-b-10">
											<i className="fa fa-lock"/>{"  "}
											<Link to="/forgotpassword">Mot de passe oublie?</Link>
										</span>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div id="particles-js"></div>
			</div>
		</>
	);
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
	loggedIn: (data) => dispatch(loggedIn(data)),
	loggedOut: (data) => dispatch(loggedOut(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
