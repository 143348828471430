import React, { useState, useEffect } from 'react';
import AppBreadcrumb from "../Shared/AppBreadcrumb";
import queryString from "query-string";
import useAlert from "../../hooks/useAlert";
import httpService from "../../services/http.service";
import FecthingSpinner from "../Shared/FecthingSpinner";
import {Form, FormGroup} from "reactstrap";
import AppAlert from "../Shared/AppAlert";
import AppInput from "../Shared/AppInput";
import {useFormik} from "formik";
import * as Yup from "yup";
import {extractResponseValidationErrors} from "../../utils";

const Setting = ({history, location, match, ...props}) => {

    const initialValues = {
        current_ios_version: '',
        current_android_version: '',
        current_huawei_version: '',
        setting_fallback_base_api: '',
        setting_google_translate_api_key: '',
    };

    const query = queryString.parse(location.search);

    const [alert, setAlert, onClose] = useAlert();
    const [submitting, setSubmitting] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [setting, setSetting] = useState({});
    const [modal, setModal] = useState({settingEditForm: false});

    const onSubmit = async (values) => {

        setSubmitting(true);
        setAlert(null);

        try {

            const {data: response} = await httpService.post(`/app_settings`, values);
            setSetting(state => ({...state, ...response?.data}));

            onCancelSettingEdit();

        }
        catch ({response}) {
            const {data, status} = response;

            if(response && status === 422) {
                setAlert({
                    type: 'danger',
                    message: extractResponseValidationErrors(data)[0]
                })
            }
            else if(response && status === 500) {
                setAlert({
                    type: 'danger',
                    message: 'Erreur de traitement, veuillez contacter les administrateurs'
                })
            }
        }
        finally {
            setSubmitting(false);
        }

    };

    const onSettingEdit = () => {
        formik.setValues({
            ...initialValues,
            current_ios_version: setting.current_ios_version || '',
            current_android_version: setting.current_android_version || '',
            current_huawei_version: setting.current_huawei_version || '',
            setting_fallback_base_api: setting.setting_fallback_base_api || '',
            setting_google_translate_api_key: setting.setting_google_translate_api_key || '',
        });
        setModal(state => ({...state, settingEditForm: true}));
    }

    const onCancelSettingEdit = () => {
        formik.resetForm({...initialValues});
        setAlert(null);
        setModal(state => ({...state, settingEditForm: false}));
    };

    const fetchSetting = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/app_settings`, {
            params
        });
        setSetting(response.data);
        cb();
    };

    useEffect(() => {

        try {

            fetchSetting({}, () => {
                setFetching(false);
            });

        } catch (e) {
            throw e;
        }

    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            current_ios_version: Yup.string().required('Champ obligatoire'),
            current_android_version: Yup.string().required('Champ obligatoire'),
            current_huawei_version: Yup.string().required('Champ obligatoire'),
            setting_fallback_base_api: Yup.string().nullable(),
            setting_google_translate_api_key: Yup.string().nullable(),
        }),
        onSubmit
    });

  return (
      <>
          <div className="container-fluid">
              <AppBreadcrumb title="Paramètres"/>
              {fetching ? <FecthingSpinner /> : (
                  <>
                      <div className="row clearfix">
                          <div className="col-md-12">
                              <button className="btn btn-warning text-white btn-sm mr-1 font-weight-bold" onClick={onSettingEdit}>
                                  <i className="fa fa-edit"></i>{' '}
                                  <span>Modifier</span>
                              </button>
                          </div>
                          <div className="col-md-12">
                              <div className="table-responsive">
                                  <table className="table table-hover table-custom spacing8">
                                      <thead>
                                      <tr>
                                          <th>Paramètre</th>
                                          <th></th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr>
                                          <td>Version iOS</td>
                                          <td>{setting['current_ios_version'] || '-'}</td>
                                      </tr>
                                      <tr>
                                          <td>Version Android</td>
                                          <td>{setting['current_android_version'] || '-'}</td>
                                      </tr>
                                      <tr>
                                          <td>Version Huawei</td>
                                          <td>{setting['current_huawei_version'] || '-'}</td>
                                      </tr>
                                      <tr>
                                          <td>Base API</td>
                                          <td>{setting['setting_fallback_base_api'] || '-'}</td>
                                      </tr>
                                      <tr>
                                          <td>Google Translate API Key</td>
                                          <td>{setting['setting_google_translate_api_key'] || '-'}</td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>

                      <div className={`modal fade ${modal.settingEditForm ? 'd-block show' : ''}`} id="exampleModal"  onClick={() => null}>
                          <div className="modal-dialog modal-md" role="document">
                              <Form onSubmit={formik.handleSubmit}>
                                  <div className="modal-content">
                                      <div className="modal-header">
                                          <h5 className="modal-title" id="exampleModalLabel">Mise a jour des paramètres</h5>
                                          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancelSettingEdit}>
                                              <span aria-hidden="true">×</span>
                                          </button>
                                      </div>
                                      <div className="modal-body">
                                          {alert && <AppAlert onClose={onClose} type={alert.type}>{alert.message}</AppAlert>}
                                          <FormGroup>
                                              <AppInput
                                                  label="Version iOS"
                                                  name="current_ios_version"
                                                  error={formik.errors.current_ios_version}
                                                  touched={formik.touched.current_ios_version}
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  value={formik.values.current_ios_version}
                                                  required
                                              />
                                          </FormGroup>
                                          <FormGroup>
                                              <AppInput
                                                  label="Version Android"
                                                  name="current_android_version"
                                                  error={formik.errors.current_android_version}
                                                  touched={formik.touched.current_android_version}
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  value={formik.values.current_android_version}
                                                  required
                                              />
                                          </FormGroup>
                                          <FormGroup>
                                              <AppInput
                                                  label="Version Huawei"
                                                  name="current_huawei_version"
                                                  error={formik.errors.current_huawei_version}
                                                  touched={formik.touched.current_huawei_version}
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  value={formik.values.current_huawei_version}
                                                  required
                                              />
                                          </FormGroup>
                                          <FormGroup>
                                              <AppInput
                                                  label="Fallback Base API"
                                                  name="setting_fallback_base_api"
                                                  type="url"
                                                  error={formik.errors.setting_fallback_base_api}
                                                  touched={formik.touched.setting_fallback_base_api}
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  value={formik.values.setting_fallback_base_api}
                                                  placeholder="https://domain.com"
                                              />
                                          </FormGroup>
                                          <FormGroup>
                                              <AppInput
                                                  label="Google Translate API Key"
                                                  name="setting_google_translate_api_key"
                                                  error={formik.errors.setting_google_translate_api_key}
                                                  touched={formik.touched.setting_google_translate_api_key}
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  value={formik.values.setting_google_translate_api_key}
                                              />
                                          </FormGroup>
                                      </div>
                                      <div className="modal-footer">
                                          <button type="button" className={`btn btn-round btn-danger font-weight-bold`} disabled={submitting} data-dismiss="modal" onClick={onCancelSettingEdit}>
                                              <span className="fa fa-times"></span>{' '}Annuler
                                          </button>
                                          <button type="submit" className={`btn btn-round btn-success font-weight-bold`} disabled={submitting}>
                                              <span className="fa fa-save"></span>{' '}{submitting ? 'Enregistrement...' : 'Enregistrer'}
                                          </button>
                                      </div>
                                  </div>
                              </Form>
                          </div>
                      </div>

                  </>
              )}
          </div>
      </>
  );
};

export default Setting;