import React from "react";

const AppAlert = ({type = "primary", icon, children, onClose = () => null, ...props}) => {
    return (
        <>
            <div className={`alert alert-${type} alert-dismissible text-left font-weight-bold`} role="alert">
                <button type="button" onClick={onClose} className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                {icon && <i className={`fa ${icon}`}/>}
                {children}
            </div>
        </>
    )
}

export default AppAlert;