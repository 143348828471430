import React, {useEffect, useState} from 'react';
import FecthingSpinner from "../../Shared/FecthingSpinner";
import httpService from "../../../services/http.service";
import MyOpinionLogo from "../../../assets/images/MyOpinion_Logo_Head.png";
import {getUserFullName} from "../../../utils/index";
import {Link} from "react-router-dom";

const UserFriends = ({user, ...props}) => {

    const [fectching, setFectching] = useState(true);
    const [friends, setFriends] = useState([]);

    const fetchFriends = async (username, cb = () => null) => {
        const { data: response } = await httpService.get(`/users/${username}/friends`);
        setFriends(response.data);
        cb();
    }

    useEffect(() => {

        try {
            fetchFriends(user.user_username, () => {
                setFectching(false);
            });
        } catch (e) {
            throw e;
        }

    }, []);

    if(fectching) {
        return <FecthingSpinner />
    }

    return (
        <div className="row clearfix">
            <div className="col-lg-12">
                <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                        <tbody>
                        {friends.map(friend => (
                            <tr key={friend.user_id}>
                                <td className="w60">
                                    <img src={friend?.profile?.prof_picture || MyOpinionLogo}
                                         data-toggle="tooltip"
                                         data-placement="top"
                                         title=""
                                         alt="Avatar"
                                         className="w35 rounded"
                                         data-original-title="Avatar Name" />
                                </td>
                                <td>
                                    <Link to={`/users/${friend.user_username}`}>
                                        {getUserFullName(friend)}{' '}
                                        {friend?.user_verified && (<span className="badge badge-primary" title="Compte certifie">
                                            <i className="fa fa-check"></i>
                                        </span>)}
                                    </Link>
                                    <p className="mb-0">
                                        @{friend.user_username}{' '}{friend?.user_muted ? (
                                        <span className="badge badge-danger" title="Mute">
                                            <i className="fa fa-volume-off"></i>
                                        </span>
                                    ) : null}
                                    </p>
                                </td>
                                <td>Pote depuis le {friend.budd_response_date}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )

}

export default UserFriends;