import React, {useState, useEffect} from 'react';
import {useFormik} from "formik";
import * as Yup from 'yup';
import AppBreadcrumb from "../Shared/AppBreadcrumb";
import FecthingSpinner from "../Shared/FecthingSpinner";
import useAlert from "../../hooks/useAlert";
import httpService from "../../services/http.service";
import AppInput from "../Shared/AppInput";
import {connect} from "react-redux";
import AppAlert from "../Shared/AppAlert";
import {extractResponseValidationErrors} from "../../utils/index";
import {profileUpdated} from "../../actions/authAction";
import * as jwtService from '../../services/jwt.service';

const Profile = ({profileUpdated, ...props}) => {

    const initialValues = {
        nom: '',
        prenom: '',
        email: '',
        password: '',
        new_password: '',
        new_password_confirmation: '',
    };

    const [fetching, setFetching] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [alert, setAlert, onClose] = useAlert();

    const getProfile = async (cb = () => null) => {

        const {data: response} = await httpService.get(`/auth/profile`);

        await formik.setValues({
            ...initialValues,
            nom: response.nom,
            prenom: response.prenom,
            email: response.email,
        });

        await formik.setTouched({
            nom: true,
            prenom: true,
            email: true,
            password: true,
        });
        cb();
    }

    useEffect(() => {
        try {
            getProfile(() => {
                setFetching(false);
            });
        } catch (e) {
            throw e;
        }
    }, []);

    const onSubmit = async (values) => {
        setSubmitting(true);
        setAlert(null);
        try {
            const {data: response} = await httpService.put(`/auth/profile`, values);

            const newJwt = {
                ...jwtService.get(),
                nom: response.data.nom,
                prenom: response.data.prenom,
                email: response.data.email,
            };

            jwtService.set(newJwt, profileUpdated);

            await formik.setValues({
                ...values,
                password: '',
                new_password: '',
                new_password_confirmation: ''
            });

            setAlert({
                type: 'success',
                message: 'Mise a jour du profile effectuee !'
            });

        } catch ({response}) {
            const {data, status} = response;

            if(response && status == 422) {
                setAlert({
                    type: 'danger',
                    message: extractResponseValidationErrors(data)[0]
                })
            }
            else if(response && status == 500) {
                setAlert({
                    type: 'danger',
                    message: 'Erreur de traitement, veuillez contacter les administrateurs'
                })
            }
        } finally {
            setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            nom: Yup.string().ensure().required('Champ obligatoire'),
            prenom: Yup.string().ensure().required('Champ obligatoire'),
            email: Yup.string().email('Format email invalide').ensure().required('Champ obligatoire'),
            password: Yup.string().ensure().required('Champ obligatoire').min(6, 'Minimun 6 carateres'),
            new_password: Yup.string().ensure().nullable(),
            new_password_confirmation: Yup.string().ensure().nullable().when('new_password', {
                is: (password) => password,
                then: Yup.string().ensure().oneOf([
                    Yup.ref('new_password')
                ], 'Le mot de passe ne correspond pas').required('Champ obligatoire')
            }),
        }),
        onSubmit
    });

    console.log(formik.values)
    console.log(formik.errors)

    return (
        <div className="container-fluid">
            <AppBreadcrumb title="Profile moderateur" />

            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="card border shadow-sm">
                                <div className="card-header bg-info text-white font-weight-bold">
                                    <i className="fa fa-user"></i>{' '}
                                    <span>Mon profile</span>
                                </div>
                                <div className="card-body">
                                    {alert && <AppAlert onClose={onClose} type={alert.type}>{alert.message}</AppAlert>}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <AppInput
                                                name="nom"
                                                label="Nom"
                                                value={formik.values.nom}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.errors.nom}
                                                touched={formik.touched.nom}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <AppInput
                                                name="prenom"
                                                label="Prenom"
                                                value={formik.values.prenom}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.errors.prenom}
                                                touched={formik.touched.prenom}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <AppInput
                                        name="email"
                                        label="Adresse email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.errors.email}
                                        touched={formik.touched.email}
                                        required
                                    />
                                    <AppInput
                                        type="password"
                                        name="password"
                                        label="Mot de passe"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.errors.password}
                                        touched={formik.touched.password}
                                        required
                                    />
                                    <hr/>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <AppInput
                                                type="password"
                                                name="new_password"
                                                label="Nouveau mot de passe"
                                                value={formik.values.new_password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.errors.new_password}
                                                touched={formik.touched.new_password}
                                                required={false}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <AppInput
                                                type="password"
                                                name="new_password_confirmation"
                                                label="Confirmation du nouveau mot de passe"
                                                value={formik.values.new_password_confirmation}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.errors.new_password_confirmation}
                                                touched={formik.touched.new_password_confirmation}
                                                required={!!formik.values.new_password}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer bg-white text-right">
                                    <button type="submit" className="btn btn-success" disabled={submitting}>
                                        <i className="fa fa-save"></i>{' '}
                                        <span className="font-weight-bold">{submitting ? 'Mise a jour...' : 'Mettre a jour'}</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );

}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    profileUpdated: (data) => dispatch(profileUpdated(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);