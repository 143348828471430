import React from 'react';
import {FormGroup, Label} from "reactstrap";

const AppSelect = ({
                       placeholder = 'Selectionnez',
                       onChange = () => null,
                       onBlur = () => null,
                       label = '',
                       value = '',
                       name = '',
                       error = null,
                       touched = false,
                       required = false,
                       children,
                       ...props
                   }) => {

    return (
        <FormGroup>
            {label && (<Label
                htmlFor={name}
                className="control-label">
                {label}
            </Label>)} {required && <span className="text-danger">*</span>}
            <select id={name} name={name} value={value} className="form-control" placeholder="Selectionnez" onChange={onChange} onBlur={onBlur}>
                <option value="">{placeholder}</option>
                {children || null}
            </select>
            {error && touched && (
                <em className="text-danger font-small italic font-weight-bold">{error}</em>
            )}
        </FormGroup>
    )
};

export default AppSelect;