import React, {useEffect, useState} from 'react';
import queryString from "query-string";
import httpService from "../../../services/http.service";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import MyOpinionLogo from "../../../assets/images/MyOpinion_Logo_Head.png";
import {Link} from "react-router-dom";
import AppPagination from "../../Shared/AppPagination";

const VerificationDemandList = ({history, location, match, ...props}) => {

    const query = queryString.parse(location.search);

    const initialValues = {
        page: query?.page || 1
    }

    const [fetching, setFetching] = useState(true);
    const [verifications, setVerifications] = useState([]);
    const [paginationData, setPaginationData] = useState({
        currentPage:  query?.page || 1,
        lastPage: Infinity,
        hasNext: true,
        hasPrevious: false,
        totalItems: Infinity
    });

    const fetchVerifications = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/verifications`, {
            params
        });
        setVerifications(response.data);
        setPaginationData(state => ({
            ...state,
            currentPage: response?.meta?.current_page,
            lastPage: response?.meta?.last_page,
            totalItems: response?.meta?.total,
            hasNext: !!response?.links?.next,
            hasPrevious: !!response?.links?.prev
        }));
        cb();
    };

    const handlePagePaginate = async (direction) => {

        let page = parseInt(query?.page || 1);

        const nextPage = direction === 'previous' ? --page : ++page;

        const data = {
            page: nextPage
        };

        history.push(`/verifications?${queryString.stringify(data)}`);

    }

    useEffect(() => {
        setFetching(true);

        try {

            fetchVerifications({
                page: query?.page || 1
            }, () => {
                setFetching(false);
            })

        } catch (e) {
            throw e;
        }

    }, [
        query?.page
    ]);

    return (
        <div className="container-fluid">
            <AppBreadcrumb title="Verifications des profils utilisateurs" />

            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-custom spacing8">
                                <thead>
                                <tr>
                                    <th>Photo</th>
                                    <th>Utilisateur</th>
                                    <th>Pays</th>
                                    <th>Date de soumission</th>
                                    <th>Statut</th>
                                    <th>-</th>
                                </tr>
                                </thead>
                                <tbody>
                                {verifications.map(verification => (
                                    <tr key={verification?.verif_demand_id}>
                                        <td className="w60">
                                            <img src={verification?.user?.profile?.prof_picture || MyOpinionLogo} data-toggle="tooltip"
                                                 data-placement="top" title="Avatar Name" alt="Avatar"
                                                 className="w35 rounded"/>
                                        </td>
                                        <td>
                                            {`${verification?.user?.user_surname} ${verification?.user?.user_name}`} (@{verification?.user?.user_username}){' '}
                                        </td>
                                        <td>
                                            <span>{verification?.user?.user_ip_pays || '-'}</span>
                                        </td>
                                        <td>{verification?.verif_demand_submitted_at}</td>
                                        <td>
                                            {verification?.verif_demand_status === 'Pending' && (
                                                <span className="badge badge-warning  ml-0 mr-0">En attente</span>
                                            )}
                                            {verification?.verif_demand_status === 'Success' && (
                                                <span className="badge badge-success  ml-0 mr-0">Valide</span>
                                            )}
                                            {verification?.verif_demand_status === 'Failed' && (
                                                <span className="badge badge-danger  ml-0 mr-0">Refuse</span>
                                            )}
                                        </td>
                                        <td>
                                            <Link to={`/verifications/${verification?.verif_demand_id}/details`}>
                                                <button className="btn btn-outline-info btn-sm font-weight-bold">
                                                    <i className="fa fa-eye"></i>{' '}
                                                    Consulter
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="body">
                                <AppPagination
                                    onNavigate={handlePagePaginate}
                                    currentPage={paginationData.currentPage}
                                    hasNext={paginationData.hasNext}
                                    hasPrevious={paginationData.hasPrevious}
                                    totalItems={paginationData.totalItems}
                                    lastPage={paginationData.lastPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default VerificationDemandList;