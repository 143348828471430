import React, {useState, useEffect} from 'react';
import httpService from "../../../services/http.service";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import AppOpinion from "../../Shared/AppOpinion";

const UserOpinions = ({user, ...props}) => {

    const [fectching, setFectching] = useState(true);
    const [opinions, setOpinions] = useState([]);
    const [perPage, setPerPage] = useState(null);

    const fetchOpinions = async (username, cb = () => null) => {
        const { data: response } = await httpService.get(`/users/${username}/opinions`);

        const opinions = (response.data || []).map(obj => {

            const attachments = [];

            if(obj?.sond_type && obj?.sond_picture) {
                attachments.push({
                    attach_url: obj?.sond_picture,
                    attach_type: obj?.sond_type,
                    attach_thumbnail: obj?.sond_thumbnail,
                });
            }

            (obj?.attachments || []).forEach(attach => attachments.push({
                attach_url: attach.attach_url,
                attach_type: attach.attach_type,
                attach_thumbnail: attach.attach_thumbnail,
            }));

            return {
                ...obj,
                attachments
            }

        });

        setOpinions(opinions);
        setPerPage(response.meta.per_page);
        cb();
    }

    useEffect(() => {

        try {
            fetchOpinions(user.user_username, () => {
                setFectching(false);
            });
        } catch (e) {
            throw e;
        }

    }, []);

    if(fectching) {
        return <FecthingSpinner />
    }

    return (
        <div className="row clearfix">
            <div className="col-md-12">
                <div className="card">
                    <div className="header"><h2>{perPage} dernieres opinions</h2></div>
                    <div className="testimonials">
                        {opinions.map(opinion => (
                            <AppOpinion
                                user={user}
                                opinion={opinion}
                                key={opinion.sond_id}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default UserOpinions;