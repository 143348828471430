import React from 'react';
import {FormGroup, Label, Input} from "reactstrap";

const AppInput = ({label, name, value, required = false, error = null, touched = false, onChange = () => null, onBlur = () => null, type = 'text', placeholder = 'Saisissez...', ...props}) => {
    return (
        <FormGroup className="text-left">
            {label && (<Label
                    htmlFor={name}
                    className="control-label">
                    {label}
                </Label>)} {required && <span className="text-danger">*</span>}
            <Input
                type={type}
                name={name}
                className="form-control"
                id={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                {...props}
            />
            {error && touched && (
                <em className="text-danger font-small italic font-weight-bold">{error}</em>
            )}
        </FormGroup>
    );
}

export default AppInput;