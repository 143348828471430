import * as jwtService from '../services/jwt.service';

const initialState = {
    user: jwtService.get()
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'LOGGED_IN':
            return {
                ...state,
                user: action.payload
            }
        case 'PROFILE_UPDATED':
            return {
                ...state,
                user: action.payload
            }
        case 'LOGGED_OUT':
            return {
                user: null
            };
        default:
            return state;
    }
}