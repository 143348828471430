import React, {useState} from "react";
import {Link} from "react-router-dom";
import {truncate} from "../../utils";
import {getUserFullName} from "../../utils/index";
import MyOpinionLogo from "../../assets/images/MyOpinion_Logo_Head.png";
import httpService from "../../services/http.service";
import {useRouteMatch} from 'react-router-dom';

const LBL_OPINIONS_PRIV = process.env.REACT_APP_SOCIAL_HUB ? 'Contacts' : 'Potes';
const LBL_OPINIONS_PUB = process.env.REACT_APP_SOCIAL_HUB ? 'Public' : 'Public';

const AppOpinion = ({user, opinion: data, toggleMute = () => null, shouldTruncate = true, truncateLength = 200, ...props}) => {


    const routeMatch = useRouteMatch();

    const [opinion, setOpinion] = useState(data);

    const handleToggle = async (opinion) => {
        if(!window.confirm('Confirmer ?')) return;

        try {
            const { data: response } = await httpService.put(`/opinions/${opinion.sond_id}/toggleMute`);
            setOpinion(opinion => ({
                ...opinion,
                sond_mute: !opinion.sond_mute
            }));
        } catch (e) {
            throw e;
        }
    }

    return (
        <div className="card mb-1" key={opinion?.sond_id}>
            <div className="body">
                <blockquote className="blockquote mb-0">
                    <footer className="blockquote-footer mb-1">
                        <Link to={`/users/${user?.user_username}`}>
                            <small className="text-muted font-weight-bold" style={{cursor: "pointer"}}>
                                <img
                                    className="avatar sm rounded-circle mr-1"
                                    src={user?.profile?.prof_picture || MyOpinionLogo}
                                    alt="avatar"
                                />
                                {getUserFullName(user, true)}
                            </small>
                        </Link>
                        <small className="float-right text-muted">{opinion.sond_created_at}</small>
                    </footer>
                    <p>
                        {opinion.agora && (
                            <Link to={`/agoras/${opinion.sond_group}/members`}>
                                <span className="badge badge-success mr-3" style={{cursor: "pointer"}}>
                                {opinion.agora.ago_title}
                            </span>
                                <br/>
                            </Link>
                        )}

                        <i className="fa fa-quote-left"></i>
                        {opinion.sond_caption
                            ? (shouldTruncate ? truncate(opinion.sond_caption, truncateLength) : opinion.sond_caption)
                            : '-'}
                        <br/>
                        <div id="lightgallery" className="row clearfix lightGallery">
                            {(opinion?.attachments || []).map(attachment => (
                                <div className="col-lg-3 col-md-6 m-b-30 m-r-5" key={attachment?.attach_id}>
                                    <a className="light-link" href={attachment?.attach_url} target="_blank">
                                        <img className="img-fluid rounded" src={attachment.attach_thumbnail || attachment?.attach_url} alt="" />
                                    </a>
                                </div>
                            ))}
                        </div>

                    </p>
                    <nav className="d-flex text-muted font-weight-bold">
                        {!opinion.sond_diffusion ? (
                            <span className="icon mr-3" style={{cursor: "pointer"}} title={LBL_OPINIONS_PUB}>
                                                <i className="fa fa-globe"></i>{' '}{LBL_OPINIONS_PUB}
                                            </span>
                        ) : (
                            <span className="icon mr-3" style={{cursor: "pointer"}} title={LBL_OPINIONS_PRIV}>
                                                <i className="fa fa-users"></i>{' '}{LBL_OPINIONS_PRIV}
                                            </span>
                        )}
                                            <span className="icon mr-3" style={{cursor: "pointer"}} title="Commentaires">
                                                <i className="fa fa-comments"></i>{' '}{opinion.comments_count || 0}
                                            </span>
                        <span className="icon mr-3" style={{cursor: "pointer"}} title="Soutiens">
                                                <i className="fa fa-heart"></i>{' '}{opinion.soutiens_count || 0}
                                            </span>
                        <span className="icon mr-3" style={{cursor: "pointer"}} title="Partages">
                                                <i className="fa fa-repeat"></i>{' '}{opinion.shares_count || 0}
                                            </span>
                        <span className="icon mr-3 text-warning" title="Signalement" style={{cursor: "pointer"}}>
                                                <i className="fa fa-warning"></i>{' '}{opinion.reports_count || 0}
                                            </span>
                        {opinion.sond_mute ? (<span className="icon mr-3 text-success" onClick={() => handleToggle(opinion)} style={{cursor: 'pointer'}}>
                                                <i className="fa fa-volume-up"></i>{' '}Unmute
                                            </span>) : (<span className="icon mr-3 text-danger" onClick={() => handleToggle(opinion)} style={{cursor: 'pointer'}}>
                                                <i className="fa fa-volume-off"></i>{' '}Mute
                                            </span>)}

                        {routeMatch.url != `/opinions/${opinion.sond_id}` && (
                            <>
                                <Link className="text-muted ml-auto" to={`/opinions/${opinion.sond_id}`}>
                                    <i className="fa fa-eye"></i>{' '}Voir l'opinion
                                </Link>
                            </>
                        )}
                    </nav>
                </blockquote>
            </div>
        </div>
    )
}

export default AppOpinion;