import React, {useState, useEffect} from 'react';
import queryString from "query-string";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import httpService from "../../../services/http.service";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import useAlert from "../../../hooks/useAlert";
import {truncate, getUserFullName} from "../../../utils/index";
import AppPagination from "../../Shared/AppPagination";

const AuthorizationRequestList = ({history, location, match, ...props}) => {

    const query = queryString.parse(location.search);

    const [alert, setAlert, onClose] = useAlert();
    const [submitting, setSubmitting] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [emails, setEmails] = useState([]);
    const [file, setFile] = useState(null);
    const [modal, setModal] = useState({
        fileForm: false,
        inputForm: false
    });
    const [paginationData, setPaginationData] = useState({
        currentPage:  query?.page || 1,
        lastPage: Infinity,
        hasNext: true,
        hasPrevious: false,
        totalItems: Infinity
    });

    const handlePagePaginate = async (direction) => {

        let page = parseInt(query?.page || 1);

        const nextPage = direction === 'previous' ? --page : ++page;

        const data = {
            page: nextPage
        };

        history.push(`/authorization-requests?${queryString.stringify(data)}`);

    }

    const onValidate = async (obj) => {

        const id = obj?.email_id;

        if(!window.confirm('Etes-vous sur ? \nValider autorisation ' + obj?.email_address)) return;

        try {
            const { data: response } = await httpService.get(`/authorization/${id}/grant`);
            const newArr = emails.filter(obj => obj?.email_id != id);
            setEmails(newArr);
        } catch ({response}) {
            const {data, status} = response;

            if(response && status == 500) {
                window.alert('Erreur de traitement');
            }
            if(response && status == 404) {
                window.alert('Introuvable');
            }
        }
    }

    const onDelete = async (obj) => {

        const id = obj?.email_id;

        if(!window.confirm('Etes-vous sur ? \nRefuser autorisation ' + obj?.email_address)) return;

        try {
            const { data: response } = await httpService.delete(`/authorization/${id}`);
            const newArr = emails.filter(obj => obj?.email_id != id);
            setEmails(newArr);
        } catch ({response}) {
            const {data, status} = response;

            if(response && status == 500) {
                window.alert('Erreur de traitement');
            }
            if(response && status == 404) {
                window.alert('Introuvable');
            }
        }
    }

    const fetchAuthorizations = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/authorization`, {
            params: {
                ...params,
                granted: 0
            }
        });
        setEmails(response.data);
        setPaginationData(state => ({
            ...state,
            currentPage: response?.meta?.current_page,
            lastPage: response?.meta?.last_page,
            totalItems: response?.meta?.total,
            hasNext: !!response?.links?.next,
            hasPrevious: !!response?.links?.prev
        }));
        cb();
    }

    useEffect(() => {
        setFetching(true);

        try {

            fetchAuthorizations({
                page: query?.page || 1
            }, () => {
                setFetching(false);
            });

        } catch (e) {
            throw e;
        }

    }, [
        query?.page,
    ]);

    return (
        <div className="container-fluid">
            <AppBreadcrumb title="Demandes d'autorisation"/>
            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-custom spacing8">
                                <thead>
                                <tr>
                                    <th>Utilisateur</th>
                                    <th>Adresse email</th>
                                    <th>Entreprise / Ministere</th>
                                    <th>Fonction</th>
                                    <th>Contact</th>
                                    <th>Date</th>
                                    <th>-</th>
                                </tr>
                                </thead>
                                <tbody>
                                {emails.map(obj => (
                                    <tr key={obj?.email_id}>
                                        <td>
                                            {getUserFullName({user_surname: obj?.last_name, user_name: obj?.first_name}, false)}{' '}
                                        </td>
                                        <td>{obj?.email_address}</td>
                                        <td title={obj?.employer}>{truncate(obj?.employer || '', 20) || '-'}</td>
                                        <td>{obj?.job_title || '-'}</td>
                                        <td>{obj?.phone_number || '-'}</td>
                                        <td>{obj?.email_created_at || '-'}</td>
                                        <td>
                                            <button className="btn btn-success btn-sm btn-round font-weight-bold mr-1" onClick={() => onValidate(obj)}>
                                                <i className="fa fa-check-circle"></i>
                                            </button>
                                            <button className="btn btn-danger btn-sm btn-round font-weight-bold" onClick={() => onDelete(obj)}>
                                                <i className="fa fa-times"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="body">
                                <AppPagination
                                    onNavigate={handlePagePaginate}
                                    currentPage={paginationData.currentPage}
                                    hasNext={paginationData.hasNext}
                                    hasPrevious={paginationData.hasPrevious}
                                    totalItems={paginationData.totalItems}
                                    lastPage={paginationData.lastPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

};

export default AuthorizationRequestList;