import React from 'react';

const AppCheckbox = ({label, name, onChange, checked = false, ...props}) => {

    return (
        <div className="fancy-checkbox">
            <label>
                <input
                    name={name}
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                />
                <span>{label}</span>
            </label>
        </div>
    )

}

export default AppCheckbox;