import React, {useState, useEffect} from 'react';
import FecthingSpinner from "../../Shared/FecthingSpinner";
import httpService from "../../../services/http.service";
import MyOpinionLogo from "../../../assets/images/MyOpinion_Logo_Head.png";
import {Link} from "react-router-dom";
import {truncate} from "../../../utils";

const UserAgoras = ({user, ...props}) => {

    const [fectching, setFectching] = useState(true);
    const [agoras, setAgoras] = useState([]);

    const fetchAgoras = async (username, cb = () => null) => {
        const { data: response } = await httpService.get(`/users/${username}/agoras`);
        setAgoras(response.data);
        cb();
    }

    useEffect(() => {

        try {
            fetchAgoras(user.user_username, () => {
                setFectching(false);
            });
        } catch (e) {
            throw e;
        }

    }, []);

    if(fectching) {
        return <FecthingSpinner />
    }

    return (
        <div className="row clearfix">
            <div className="col-lg-12">
                <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                        <tbody>
                        {agoras.map(agora => (
                            <tr key={agora?.ago_id}>
                                <td className="w60">
                                    <img
                                        src={agora.ago_picture || MyOpinionLogo}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title=""
                                        alt="Avatar"
                                        className="w35 rounded"
                                        data-original-title="Avatar Name" />
                                </td>
                                <td>
                                    <Link to={`/agoras/${agora.ago_id}/opinions`}>
                                        {agora.ago_title}
                                    </Link>
                                    <p className="mb-0">{agora.users_count} membres</p>
                                </td>
                                <td>
                                    <span>{truncate(agora.ago_presentation, 30) || 'Aucune description'}</span>
                                </td>
                                <td>
                                    <span>Membre depuis le {agora.agm_updated}</span>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )

}

export default UserAgoras;