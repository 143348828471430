import React, {useState, useEffect} from 'react';
import queryString from "query-string";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import httpService from "../../../services/http.service";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import useAlert from "../../../hooks/useAlert";
import MyOpinionLogo from "../../../assets/images/MyOpinion_Logo_Head.png";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Form, FormGroup} from "reactstrap";
import AppAlert from "../../Shared/AppAlert";
import AppInputFile from "../../Shared/AppInputFile";
import AppDatePicker from "../../Shared/AppDatePicker";
import {serialize} from "object-to-formdata";
import {extractResponseValidationErrors, getUserFullName, truncate} from "../../../utils/index";
import AppInput from "../../Shared/AppInput";
import AppPagination from "../../Shared/AppPagination";

const HolidayBalanceList = ({history, location, match, ...props}) => {

    const importFormInitialValues = {
        user_holiday_balance_updated_at: new Date(),
        file: '',
    };

    const updateFormInitialValues = {
        user_holiday_balance: '',
        user_holiday_balance_updated_at: new Date(),
    };

    const query = queryString.parse(location.search);

    const [alert, setAlert, onClose] = useAlert();
    const [submitting, setSubmitting] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [file, setFile] = useState(null);
    const [modal, setModal] = useState({importForm: false, updateForm: false});
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [paginationData, setPaginationData] = useState({
        currentPage:  query?.page || 1,
        lastPage: Infinity,
        hasNext: true,
        hasPrevious: false,
        totalItems: Infinity
    });

    const importFormSubmitHandler = async (values) => {

        setSubmitting(true);
        setAlert(null);

        const formData = serialize(values, {
            indices: true,
            booleansAsIntegers: true
        });

        try {
            const { data: response } = await httpService.post(`/holiday_balance/import`, formData);

            window.alert('Operation effectuee !');

            window.location.reload();
        }
        catch ({response}) {
            const {data, status} = response;

            if(response && status == 422) {
                setAlert({
                    type: 'danger',
                    message: extractResponseValidationErrors(data)[0]
                })
            }
            else if(response && status == 500) {
                setAlert({
                    type: 'danger',
                    message: 'Erreur de traitement, veuillez contacter les administrateurs'
                })
            }
        }
        finally {
            setSubmitting(false);
        }

    };

    const updateFormSubmitHandler = async (values) => {

        setSubmitting(true);
        setAlert(null);

        try {
            const { data: response } = await httpService.put(`/holiday_balance/${selectedUser?.user_id}`, values);

            const index = users.indexOf(selectedUser);
            users[index] = response.data;
            setUsers(users);

            onUpdateFormCancel();
        }
        catch ({response}) {
            const {data, status} = response;

            if(response && status == 422) {
                setAlert({
                    type: 'danger',
                    message: extractResponseValidationErrors(data)[0]
                })
            }
            else if(response && status == 500) {
                setAlert({
                    type: 'danger',
                    message: 'Erreur de traitement, veuillez contacter les administrateurs'
                })
            }
        }
        finally {
            setSubmitting(false);
        }

    };

    const onImportFormCancel = () => {
        importFormFormik.resetForm({...importFormInitialValues});
        setAlert(null);
        setFile(null);
        setModal(state => ({...state, importForm: false}));
    };

    const onUpdateFormCancel = () => {
        importFormFormik.resetForm({...updateFormInitialValues});
        setAlert(null);
        setSelectedUser(null);
        setModal(state => ({...state, updateForm: false}));
    };

    const onFileSelect = (event) => {
        const {target} = event;
        const files = target.files;

        setFile(files[0]);

        importFormFormik.setFieldValue('file', files[0], true);
        importFormFormik.setFieldTouched('file', false);
    };

    const handlePagePaginate = async (direction) => {

        let page = parseInt(query?.page || 1);

        const nextPage = direction === 'previous' ? --page : ++page;

        const data = {
            page: nextPage
        };

        history.push(`/hr/holidays_balance?${queryString.stringify(data)}`);

    }

    const fetchBalances = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/holiday_balance`, {
            params
        });
        setUsers(response.data);
        setPaginationData(state => ({
            ...state,
            currentPage: response?.meta?.current_page,
            lastPage: response?.meta?.last_page,
            totalItems: response?.meta?.total,
            hasNext: !!response?.links?.next,
            hasPrevious: !!response?.links?.prev
        }));
        cb();
    };

    const onUpdateBalance = (user) => {
        setSelectedUser(user);
        updateFormFormik.setValues({
            ...updateFormInitialValues,
            user_holiday_balance: user.user_holiday_balance,
        })
        setModal(state => ({...state, updateForm: true}));
    }

    const onDownloadFile = async () => {

        if(downloading) return;

        setDownloading(true);

        try {
            const {data: file, headers} = await httpService.post(`/holiday_balance/export`, {}, {
                responseType: "blob"
            });
            const url = window.URL.createObjectURL(new Blob([file]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', headers['filename']);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            window.alert('Erreur de traitement');
        }
        finally {
            setDownloading(false);
        }

    }

    const importFormFormik = useFormik({
        initialValues: importFormInitialValues,
        validationSchema: Yup.object().shape({
            user_holiday_balance_updated_at: Yup.string().required('Champ obligatoire'),
            file: Yup.string().required('Champ obligatoire'),
        }),
        onSubmit: importFormSubmitHandler
    });

    const updateFormFormik = useFormik({
        initialValues: updateFormInitialValues,
        validationSchema: Yup.object().shape({
            user_holiday_balance: Yup.string().required('Champ obligatoire'),
            user_holiday_balance_updated_at: Yup.string().required('Champ obligatoire'),
        }),
        onSubmit: updateFormSubmitHandler
    });

    useEffect(() => {
        setFetching(true);

        try {

            fetchBalances({
                page: query?.page || 1
            }, () => {
                setFetching(false);
            });

        } catch (e) {
            throw e;
        }

    }, [
        query?.page
    ]);

    return (
        <>
            <div className="container-fluid">
                <AppBreadcrumb title="Soldes de conges du personnel"/>
                {fetching ? <FecthingSpinner /> : (
                    <div className="row clearfix">
                        <div className="col-md-12">
                            <button disabled={downloading} className="btn btn-info btn-sm mr-1 font-weight-bold" onClick={onDownloadFile}>
                                <i className="fa fa-download"></i>{' '}
                                <span>{downloading ? 'Telechargement...' : 'Telecharger le modele d\'importation'}</span>
                            </button>
                            <button className="btn btn-success btn-sm mr-1 font-weight-bold" onClick={() => setModal(state => ({...state, importForm: true}))}>
                                <i className="fa fa-upload"></i>{' '}
                                <span>Importer le fichier d'integration des soldes</span>
                            </button>
                        </div>
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-hover table-custom spacing8">
                                    <thead>
                                    <tr>
                                        <th colSpan={2}>Utilisateur</th>
                                        <th>Fonction</th>
                                        <th>Solde</th>
                                        <th>Date actualisation</th>
                                        <th>-</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users.map(obj => (
                                        <tr key={obj?.user_id}>
                                            <td className="w60">
                                                <img src={obj?.profile?.prof_picture || MyOpinionLogo} data-toggle="tooltip"
                                                     data-placement="top" title="Avatar Name" alt="Avatar"
                                                     className="w35 rounded"/>
                                            </td>
                                            <td>
                                                {getUserFullName(obj, false)}{' '}
                                            </td>
                                            <td title={obj?.profile?.prof_title}>{truncate(obj?.profile?.prof_title || '', 30) || '-'}</td>
                                            <td>{obj?.user_holiday_balance} jour(s)</td>
                                            <td>{obj?.user_holiday_balance_updated_at}</td>
                                            <td>
                                                <button className="btn btn-info btn-round btn-sm font-weight-bold" onClick={() => onUpdateBalance(obj)}>
                                                    <i className="fa fa-edit"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card">
                                <div className="body">
                                    <AppPagination
                                        onNavigate={handlePagePaginate}
                                        currentPage={paginationData.currentPage}
                                        hasNext={paginationData.hasNext}
                                        hasPrevious={paginationData.hasPrevious}
                                        totalItems={paginationData.totalItems}
                                        lastPage={paginationData.lastPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className={`modal fade ${modal.importForm ? 'd-block show' : ''}`} id="exampleModal"  onClick={() => null}>
                <div className="modal-dialog modal-md" role="document">
                    <Form onSubmit={importFormFormik.handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Importation du fichier d'actualisation des soldes de conges</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onImportFormCancel}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {alert && <AppAlert onClose={onClose} type={alert.type}>{alert.message}</AppAlert>}
                                <FormGroup>
                                    <AppDatePicker
                                        label="Date d'actualisation des soldes"
                                        className="form-control"
                                        error={importFormFormik.errors.user_holiday_balance_updated_at}
                                        touched={importFormFormik.touched.user_holiday_balance_updated_at}
                                        value={importFormFormik.values.user_holiday_balance_updated_at}
                                        onChange={(date) => importFormFormik.setFieldValue('user_holiday_balance_updated_at', date)}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <AppInputFile
                                        name="file"
                                        placeholder={file?.name}
                                        required={true}
                                        error={importFormFormik.errors.file}
                                        touched={importFormFormik.touched.file}
                                        label="Selectionnez le fichier a importer"
                                        onChange={onFileSelect}
                                        accept=".csv"
                                    />
                                </FormGroup>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className={`btn btn-round btn-danger font-weight-bold`} disabled={submitting} data-dismiss="modal" onClick={onImportFormCancel}>
                                    <span className="fa fa-times"></span>{' '}Annuler
                                </button>
                                <button type="submit" className={`btn btn-round btn-success font-weight-bold`} disabled={submitting}>
                                    <span className="fa fa-save"></span>{' '}{submitting ? 'Importation...' : 'Importer'}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

            <div className={`modal fade ${modal.updateForm ? 'd-block show' : ''}`} id="exampleModal"  onClick={() => null}>
                <div className="modal-dialog modal-md" role="document">
                    <Form onSubmit={updateFormFormik.handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Mise a jour du solde de conges</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onUpdateFormCancel}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {alert && <AppAlert onClose={onClose} type={alert.type}>{alert.message}</AppAlert>}
                                <FormGroup>
                                    <AppInput
                                        label="Utilisateur / Personnel RH"
                                        value={`${selectedUser?.user_surname} ${selectedUser?.user_name} (@${selectedUser?.user_username})`}
                                        required
                                        disabled
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <AppInput
                                        label="Solde de conges (jours)"
                                        name="user_holiday_balance"
                                        error={updateFormFormik.errors.user_holiday_balance}
                                        touched={updateFormFormik.touched.user_holiday_balance}
                                        onChange={updateFormFormik.handleChange}
                                        onBlur={updateFormFormik.handleBlur}
                                        value={updateFormFormik.values.user_holiday_balance}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <AppDatePicker
                                        label="Date d'actualisation des soldes"
                                        className="form-control"
                                        error={updateFormFormik.errors.user_holiday_balance_updated_at}
                                        touched={updateFormFormik.touched.user_holiday_balance_updated_at}
                                        value={updateFormFormik.values.user_holiday_balance_updated_at}
                                        onChange={(date) => updateFormFormik.setFieldValue('user_holiday_balance_updated_at', date)}
                                        required
                                    />
                                </FormGroup>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className={`btn btn-round btn-danger font-weight-bold`} disabled={submitting} data-dismiss="modal" onClick={onUpdateFormCancel}>
                                    <span className="fa fa-times"></span>{' '}Annuler
                                </button>
                                <button type="submit" className={`btn btn-round btn-success font-weight-bold`} disabled={submitting}>
                                    <span className="fa fa-save"></span>{' '}{submitting ? 'Importation...' : 'Importer'}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );

}

export default HolidayBalanceList;