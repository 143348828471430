import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Menu from './Menu';
import Header from './Header';
import Megamenu from './Megamenu';
import Routes from '../Route';

export default class Layout extends Component {
	render() {
		return (
			<>
				<div className="overlay" />
				<div id="wrapper">
					<Header />
					<Menu {...this.props} />
					<div id="main-content">
						<Switch>
							{Routes.map((layout, i) => {
								return <Route key={`r${i}`} exact={layout.exact} path={layout.path} component={layout.component}></Route>
							})}
							<Route path="*">
								<Redirect to="/notfound" />
							</Route>
						</Switch>
					</div>
				</div>
			</>
		);
	}
}
