import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {FormGroup} from "reactstrap";

const AppDatepicker = ({
                           label,
                           value,
                           onChange = () => null,
                           required = false,
                           placeholder = 'Selectionnez un date',
                           inline,
                           className,
                           error = null,
                           touched = false}) => {
    return (
        <FormGroup className="text-left">
            {label && <label
                htmlFor="name"
                className="control-label">{label}</label>} {required && <span className="text-danger">*</span>}
            <br/>
            <DatePicker
                selected={value}
                inline={inline}
                className={className}
                placeholderText={placeholder}
                onChange={onChange}
            />
            <br/>
            {error && touched && (
                <em className="text-danger font-small italic font-weight-bold">{error}</em>
            )}
        </FormGroup>
    );
}

export default AppDatepicker;
