import React from "react";

const FecthingSpinner = ({...props}) => {
    return (
        <div>
            <div className="text-center mt-5">
                <i className="fa fa-spinner fa-spin font-40"></i>
            </div>
        </div>
    )
}

export default FecthingSpinner;