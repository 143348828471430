import React, {useState, useEffect} from 'react';
import httpService from "../../../services/http.service";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import MyOpinionLogo from "../../../assets/images/MyOpinion_Logo_Head.png";
import {Link} from "react-router-dom";
import {truncate} from "../../../utils";
import {useFormik} from "formik";
import AppInput from "../../Shared/AppInput";
import AppPagination from "../../Shared/AppPagination";
const queryString = require('query-string');

const AgoraList = ({history, location, match, ...props}) => {

    const query = queryString.parse(location.search);

    const [fetching, setFetching] = useState(true);
    const [agoras, setAgoras] = useState([]);
    const [paginationData, setPaginationData] = useState({
        currentPage:  query?.page || 1,
        lastPage: Infinity,
        hasNext: true,
        hasPrevious: false,
        totalItems: Infinity
    });

    const initialValues = {
        keyword: query.keyword || '',
        ago_id: query.keyword || '',
        page: query?.page || 1
    }

    const fetchAgoras = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/agoras`, {
            params
        });
        setAgoras(response.data);
        setPaginationData(state => ({
            ...state,
            currentPage: response?.meta?.current_page,
            lastPage: response?.meta?.last_page,
            totalItems: response?.meta?.total,
            hasNext: !!response?.links?.next,
            hasPrevious: !!response?.links?.prev
        }));
        cb();
    };

    const onSubmit = async (values) => {

        setFetching(true);

        const data = {
            ...initialValues,
            ...values,
            page: 1
        };

        history.push(`/agoras?${queryString.stringify(data)}`);

    };

    const onReset = () => {
        formik.resetForm({
            ago_id: '',
            keyword: '',
            page: 1,
        });

        history.push(`/agoras`);
    }

    const handlePagePaginate = async (direction) => {

        let page = parseInt(query?.page || 1);

        const nextPage = direction === 'previous' ? --page : ++page;

        const data = {
            ago_id: query.ago_id || '',
            keyword: query.keyword || '',
            page: nextPage
        };

        history.push(`/agoras?${queryString.stringify(data)}`);

    }

    useEffect(() => {
        setFetching(true);

        try {

            fetchAgoras({
                ago_id: query.ago_id || '',
                keyword: query.keyword || '',
                page: query?.page || 1
            }, () => {
                setFetching(false);
            })

        } catch (e) {
            throw e;
        }

    }, [
        query.ago_id,
        query.keyword,
        query?.page,
    ]);

    const formik = useFormik({
        initialValues,
        onSubmit
    });

    return (
        <div className="container-fluid">
            <AppBreadcrumb title="Agoras" />

            <div className="row clearfix">
                <div className="col-md-12">
                    <div className="card border shadow-sm">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="card-header bg-info text-white font-weight-bold">
                                <i className="fa fa-search"></i>{' '}
                                <span>Filtre de recherche</span>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <AppInput
                                            name="ago_id"
                                            placeholder="Identifiant"
                                            value={formik.values.ago_id}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <AppInput
                                            name="keyword"
                                            placeholder="Mot cle"
                                            value={formik.values.keyword}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer bg-white text-right">
                                <button type="button" onClick={onReset} className="btn btn-danger mr-1">
                                    <i className="fa fa-times"></i>{' '}
                                    <span className="font-weight-bold">Annuler</span>
                                </button>
                                <button type="submit" className="btn btn-success">
                                    <i className="fa fa-check"></i>{' '}
                                    <span className="font-weight-bold">Rechercher</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-custom spacing5">
                                <tbody>
                                {agoras.map(agora => (
                                    <tr>
                                        <td className="w60">
                                            <img
                                                src={agora.ago_picture || MyOpinionLogo}
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title=""
                                                alt="Avatar"
                                                className="w35 rounded"
                                                data-original-title="Avatar Name" />
                                        </td>
                                        <td>
                                            <Link to={`/agoras/${agora.ago_id}/opinions`}>
                                                {truncate(agora.ago_title, 35)}
                                            </Link>
                                            <p className="mb-0">{agora.users_count} membres</p>
                                        </td>
                                        <td>
                                            <span>{truncate(agora.ago_presentation, 30) || 'Aucune description'}</span>
                                        </td>
                                        <td>
                                            <span>Cree le {agora.ago_created}</span>
                                        </td>
                                        <td>
                                            <Link to={`/agoras/${agora.ago_id}/members`}>
                                                <button className="btn btn-outline-success btn-sm font-weight-bold mr-1">
                                                    <i className="fa fa-users"></i>{' '}
                                                    Membres
                                                </button>
                                            </Link>
                                            <Link to={`/agoras/${agora.ago_id}/opinions`}>
                                                <button className="btn btn-outline-info btn-sm font-weight-bold">
                                                    <i className="fa fa-edit"></i>{' '}
                                                    Opinions
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="body">
                                <AppPagination
                                    onNavigate={handlePagePaginate}
                                    currentPage={paginationData.currentPage}
                                    hasNext={paginationData.hasNext}
                                    hasPrevious={paginationData.hasPrevious}
                                    totalItems={paginationData.totalItems}
                                    lastPage={paginationData.lastPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AgoraList;