import React, {useState, useEffect} from 'react';
import httpService from "../../../services/http.service";
import queryString from "query-string";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import {useFormik} from "formik";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import AppComment from "../../Shared/AppComment";
import AppOpinion from "../../Shared/AppOpinion";
import AppUser from "../../Shared/AppUser";
import * as Yup from 'yup';
import {Col, Row} from "reactstrap";
import UserCardBody from "../../Shared/User/Body";

const AwardList = ({history, location, match, ...props}) => {

    const query = queryString.parse(location.search);

    const typesAward = [
        {label: 'Top 10 utilisateurs', value: 'User'},
        {label: 'Top opinion', value: 'Opinion'},
        {label: 'Top commentaire', value: 'Comment'},
        {label: 'Top suggestion', value: 'Suggestion'},
    ];

    const [fetching, setFetching] = useState(true);
    const [filtering, setFiltering] = useState(false);
    const [awards, setAwards] = useState([]);
    const [awardsPeriodes, setAwardsPeriodes] = useState([]);

    const initialValues = {
        periode_id: '',
        type: '',
    };

    const fetchAwards = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/awards`, {
            params
        });
        setAwards(response?.data);
        cb();
    };

    const fetchAwardsPeriodes = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/awards_periodes`, {
            params
        });
        setAwardsPeriodes(response?.data);
        cb();
    };

    const onSubmit = async (values) => {
        setFiltering(true);

        try {
            fetchAwards(values, () => {
               setFiltering(false);
            });
        } catch (e) {
            throw e;
        }
    };

    const onReset = () => {
        formik.resetForm(initialValues);
        setAwards([]);
    }

    const topType = (award) => {
        if(award?.award_model_type === 'App\\Models\\User') {
            return `Utilisateur top ${award?.award_datas?.user_position || 1} , Du ${award?.periode?.lbl_periode}`;
        }
        else if(award?.award_model_type === 'App\\Models\\Opinion') {
            return `Top opinion, Du ${award?.periode?.lbl_periode}`
        }
        else if(award?.award_model_type === 'App\\Models\\Comment') {
            if(award?.model?.com_type === 'c') {
                return `Top commentaire, Du ${award?.periode?.lbl_periode}`;
            } else {
                return `Top suggestion, Du ${award?.periode?.lbl_periode}`;
            }
        }
    };

    const topHeaderClass = (award) => {
        if(award?.award_model_type === 'App\\Models\\User') {
            return `bg-primary`;
        }
        else if(award?.award_model_type === 'App\\Models\\Opinion') {
            return `bg-info`
        }
        else if(award?.award_model_type === 'App\\Models\\Comment') {
            if(award?.model?.com_type === 'c') {
                return `bg-success`;
            } else {
                return `bg-warning`;
            }
        }
    };

    const topContent = (award) => {
        if(award?.award_model_type === 'App\\Models\\Comment') {
            return <AppComment comment={award?.model} user={award?.model?.user} />
        }
        else if(award?.award_model_type === 'App\\Models\\Opinion') {
            return <AppOpinion opinion={award?.model} user={award?.model?.user} />
        }
        else if(award?.award_model_type === 'App\\Models\\User') {
            return (
                <div className="card c_grid c_blue border shadow-sm">
                    <div className="card-body">
                        <Row>
                            <Col md={5}>
                                <UserCardBody className="text-center" user={award?.model} />
                            </Col>
                            <Col md={7} className="d-flex flex-column justify-content-center">
                                <h5>
                                    <i className="fa fa-bar-chart-o"></i>{' '}
                                    <span>Resumés des activités</span>
                                </h5>
                                <h6 className="text-muted my-3">
                                    <i className="fa fa-pencil-square-o"></i>{' '}
                                    <span>Opinion publiées: {award?.award_datas?.user_opinions || 0}</span>
                                </h6>
                                <h6 className="text-muted my-3">
                                    <i className="fa fa-thumbs-o-up"></i>{' '}
                                    <span>Soutiens obtenus: {award?.award_datas?.user_soutiens || 0}</span>
                                </h6>
                                <h6 className="text-muted my-3">
                                    <i className="fa fa-comments-o"></i>{' '}
                                    <span>Commentaires: {award?.award_datas?.user_comments || 0}</span>
                                </h6>
                                <h6 className="text-muted my-3">
                                    <i className="fa fa-bookmark-o"></i>{' '}
                                    <span>Suggestions: {award?.award_datas?.user_suggestions || 0}</span>
                                </h6>
                            </Col>
                        </Row>
                    </div>
                </div>
            )
        }

        return null;
    }

    const topIcon = (award) => {
        if(award?.award_model_type === 'App\\Models\\User') {
            return 'fa-user';
        }
        else if(award?.award_model_type === 'App\\Models\\Opinion') {
            return 'fa-pencil-square-o'
        }
        else if(award?.award_model_type === 'App\\Models\\Comment') {
            if(award?.model?.com_type === 'c') {
                return 'fa-comments-o';
            } else {
                return 'fa-bookmark-o';
            }
        }
    };

    useEffect(() => {

        try {
            fetchAwardsPeriodes({}, () => {
                setFetching(false);
            });
        } catch (e) {
            throw e;
        }

    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            periode_id: Yup.string().ensure().required('Champ obligatoire'),
            type: Yup.string().ensure().required('Champ obligatoire'),
        }),
        onSubmit
    });

    if(fetching) {
        return <FecthingSpinner />
    }

    return (
        <div className="container-fluid">
            <AppBreadcrumb title="Awards" />

            <div className="row clearfix">
                <div className="col-md-12">
                    <div className="card border shadow-sm">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="card-header bg-info text-white font-weight-bold">
                                <i className="fa fa-calendar"></i>{' '}
                                <span>Periodes</span>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <select name="periode_id" value={formik.values.periode_id} onChange={formik.handleChange} className="form-control">
                                            <option disabled value="">Periode</option>
                                            {awardsPeriodes.map(periode => (
                                                <option key={periode.award_periode_id} value={periode.award_periode_id}>{periode.lbl_periode}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <select name="type" value={formik.values.type} onChange={formik.handleChange} className="form-control">
                                            <option disabled value="">Type d'award</option>
                                            {typesAward.map(type => (
                                                <option key={type.value} value={type.value}>{type.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer bg-white text-right">
                                <button type="button" onClick={onReset} className="btn btn-danger mr-1">
                                    <i className="fa fa-times"></i>{' '}
                                    <span className="font-weight-bold">Annuler</span>
                                </button>
                                <button type="submit" className="btn btn-success">
                                    <i className="fa fa-star"></i>{' '}
                                    <span className="font-weight-bold">Afficher</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {filtering ? <FecthingSpinner /> : (
                <>
                    {awards.map(award => (
                        <div className="row clearfix" key={award.award_id}>
                            <div className="col-md-12 mb-3">
                                <div className="card border shadow-sm mb-0">
                                    <div className={`card-header ${topHeaderClass(award)} text-white font-weight-bold`}>
                                        <i className={`fa ${topIcon(award)}`}></i>{' '}
                                        <span>{topType(award)}</span>
                                    </div>
                                </div>
                                {topContent(award)}
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    );

};

export default AwardList;