import React, {useEffect, useState} from 'react';
import httpService from "../../../services/http.service";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import {Link} from "react-router-dom";
import {getUserFullName} from '../../../utils/index';
import MyOpinionLogo from "../../../assets/images/MyOpinion_Logo_Head.png";

const OpinionMembre = ({history, location, match, ...props}) => {

    const [fetching, setFetching] = useState(true);
    const [agora, setAgora] = useState([]);

    const fetchAgora = async (id, cb = () => null) => {
        const { data: response } = await httpService.get(`/agoras/${id}/members`);
        setAgora(response.data);
        cb();
    };

    useEffect(() => {

        try {

            fetchAgora(match.params.id, () => {
                setFetching(false);
            });

        } catch (e) {
            throw e;
        }

    }, []);

    if(fetching) {
        return <FecthingSpinner />
    }

    return (
        <div className="container-fluid">
            <AppBreadcrumb title={`${agora.ago_title}: Membres`}>
                <div className="col-md-6 col-sm-12 text-right">
                    <Link to={'/agoras'}>
                        <button className="btn btn-sm btn-default font-weight-bold mr-1">
                            <i className="fa fa-arrow-left"></i>{' '}
                            <span>Retour</span>
                        </button>
                    </Link>
                    <Link to={`/agoras/${agora.ago_id}/opinions`}>
                        <button className="btn btn-sm btn-info font-weight-bold">
                            <i className="fa fa-edit"></i>{' '}
                            <span>Opinions</span>
                        </button>
                    </Link>
                </div>
            </AppBreadcrumb>

            <div className="row clearfix">
                <div className="col-md-12">
                    <div className="card">
                        <div className="header"><h2>{agora.ago_presentation || 'Aucune presentation'}</h2></div>
                        <div className="table-responsive">
                            <table className="table table-hover table-custom spacing8">
                                <thead>
                                <tr>
                                    <th className="w60">Membre</th>
                                    <th></th>
                                    <th></th>
                                    <th>Membre depuis le</th>
                                </tr>
                                </thead>
                                <tbody>
                                {agora.users.map(user => (
                                    <tr>
                                        <td>
                                            <Link to={`/users/${user.user_username}`}>
                                                <img
                                                    className="rounded"
                                                    src={user?.profile?.prof_picture || MyOpinionLogo}
                                                    height="60px"
                                                    width="60px"
                                                    alt="Avatar" />
                                            </Link>
                                        </td>
                                        <td>
                                            <h6 className="mb-0">{getUserFullName(user, true)}</h6>
                                            <span>{user.user_email}</span>
                                        </td>
                                        <td>
                                            {user.agm_role == '0' && '-'}
                                            {user.agm_role == '1' && <span className="badge badge-success">Moderateur</span>}
                                            {user.agm_role == '2' && <span className="badge badge-danger">Administrateur</span>}
                                        </td>
                                        <td>{user.agm_updated}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OpinionMembre;