import React, {useState, useEffect} from 'react';
import httpService from "../../../services/http.service";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import AppOpinion from "../../Shared/AppOpinion";
import {useFormik} from "formik";
import AppInput from "../../Shared/AppInput";
import AppPagination from "../../Shared/AppPagination";
const queryString = require('query-string');

const OpinionList = ({history, location, match, ...props}) => {

    const query = queryString.parse(location.search);


    const [opinions, setOpinions] = useState([]);
    const [fetching, setFetching] = useState(true);

    const [paginationData, setPaginationData] = useState({
        currentPage:  query?.page || 1,
        lastPage: Infinity,
        hasNext: true,
        hasPrevious: false,
        totalItems: Infinity
    });

    const initialValues = {
        sond_id: query.sond_id || '',
        keyword: query.keyword || '',
        page: query.page || 1
    };

    const fetchOpinions = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/opinions`, {
            params
        });

        const opinions = (response.data || []).map(obj => {

            const attachments = [];

            if(obj?.sond_type && obj?.sond_picture) {
                attachments.push({
                    attach_url: obj?.sond_picture,
                    attach_type: obj?.sond_type,
                    attach_thumbnail: obj?.sond_thumbnail,
                });
            }

            (obj?.attachments || []).forEach(attach => attachments.push({
                attach_url: attach.attach_url,
                attach_type: attach.attach_type,
                attach_thumbnail: attach.attach_thumbnail,
            }));

            return {
                ...obj,
                attachments
            }

        });

        setOpinions(opinions);
        setPaginationData(state => ({
            ...state,
            currentPage: response?.meta?.current_page,
            lastPage: response?.meta?.last_page,
            totalItems: response?.meta?.total,
            hasNext: !!response?.links?.next,
            hasPrevious: !!response?.links?.prev
        }));
        cb();
    };

    const onReset = () => {

        formik.resetForm({
            keyword: '',
            sond_id: '',
            page: 1
        });

        history.replace(`/opinions`);

    }

    const onSubmit = async (values) => {

        setFetching(true);

        const data = {
            ...initialValues,
            ...values,
            page: 1
        };

        history.push(`/opinions?${queryString.stringify(data)}`);

    }

    const handlePagePaginate = async (direction) => {

        let page = parseInt(query?.page || 1);

        const nextPage = direction === 'previous' ? --page : ++page;

        const data = {
            page: nextPage,
            sond_id: query?.sond_id || '',
            keyword: query?.keyword || ''
        };

        history.push(`/opinions?${queryString.stringify(data)}`);

    }

    useEffect(() => {
        setFetching(true);

        try {
            fetchOpinions({
                ...initialValues,
                sond_id: query?.sond_id || '',
                keyword: query?.keyword || '',
                page: query?.page || 1,
            }, () => setFetching(false));
        } catch (e) {
            throw e;
        }
    }, [
        query?.sond_id,
        query?.keyword,
        query?.page,
    ]);

    const formik = useFormik({
        initialValues,
        onSubmit
    });

    return (
        <div className="container-fluid">
            <AppBreadcrumb title={process.env.REACT_APP_SOCIAL_HUB ? "Historiques des posts publiés" : "Historiques des opinions publiées"} />

            <div className="row clearfix">
                <div className="col-md-12">
                    <div className="card border shadow-sm">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="card-header bg-info text-white font-weight-bold">
                                <i className="fa fa-search"></i>{' '}
                                <span>Filtre de recherche</span>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <AppInput
                                            name="sond_id"
                                            placeholder="Identifiant"
                                            value={formik.values.sond_id}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <AppInput
                                            name="keyword"
                                            placeholder="Mot cle"
                                            value={formik.values.keyword}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer bg-white text-right">
                                <button type="button" onClick={onReset} className="btn btn-danger mr-1">
                                    <i className="fa fa-times"></i>{' '}
                                    <span className="font-weight-bold">Annuler</span>
                                </button>
                                <button type="submit" className="btn btn-success">
                                    <i className="fa fa-check"></i>{' '}
                                    <span className="font-weight-bold">Rechercher</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="testimonials">
                                {opinions.map(opinion => (
                                    <AppOpinion
                                        key={opinion.sond_id}
                                        user={opinion?.user}
                                        opinion={opinion}
                                        truncateLength={350}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="body">
                                <AppPagination
                                    onNavigate={handlePagePaginate}
                                    currentPage={paginationData.currentPage}
                                    hasNext={paginationData.hasNext}
                                    hasPrevious={paginationData.hasPrevious}
                                    totalItems={paginationData.totalItems}
                                    lastPage={paginationData.lastPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default OpinionList;